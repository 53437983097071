import ArrowForwardIcon from '@mui/icons-material/ArrowForward';
import { Typography, Box, Container, Link as MuiLink } from '@mui/material';
import { BaseCarousel } from '@/src/components/Common/BaseCarousel/BaseCarousel';
import { TestimonialCard } from './TestimonialCard';
import { testimonialsList } from './testimonialsData';
export const Testimonials = () => {
  return <Box sx={{
    bgcolor: 'primary.8p',
    py: 6
  }} data-sentry-element="Box" data-sentry-component="Testimonials" data-sentry-source-file="Testimonials.tsx">
      <Container component="section" data-sentry-element="Container" data-sentry-source-file="Testimonials.tsx">
        <Typography variant="h2" mb={4} align="center" sx={{
        typography: {
          xs: 'h4',
          md: 'h3'
        }
      }} data-sentry-element="Typography" data-sentry-source-file="Testimonials.tsx">
          Customers Love Us 💜
        </Typography>
        <BaseCarousel slidesCount={testimonialsList.length} slidesToShow={{
        xs: 1,
        sm: 2,
        md: 2,
        lg: 3,
        xl: 5
      }} data-sentry-element="BaseCarousel" data-sentry-source-file="Testimonials.tsx">
          {testimonialsList.map(testimonial => <Box key={testimonial.name} sx={{
          px: 1.5
        }}>
              <TestimonialCard testimonial={testimonial} />
            </Box>)}
        </BaseCarousel>

        <Box display="flex" justifyContent="flex-end" mt={2} data-sentry-element="Box" data-sentry-source-file="Testimonials.tsx">
          <MuiLink href="https://testimonial.to/quotebeam/all" target="_blank" rel="noopener noreferrer nofollow" sx={{
          display: 'flex',
          alignItems: 'center'
        }} data-sentry-element="MuiLink" data-sentry-source-file="Testimonials.tsx">
            see all <ArrowForwardIcon sx={{
            ml: 1
          }} data-sentry-element="ArrowForwardIcon" data-sentry-source-file="Testimonials.tsx" />
          </MuiLink>
        </Box>
      </Container>
    </Box>;
};