import Image, { StaticImageData } from 'next/image';
import LinkedInIcon from '@mui/icons-material/LinkedIn';
import { Card, CardActionArea, Stack, Typography, Box } from '@mui/material';
type TestimonialCardProps = {
  testimonial: {
    name: string;
    linkedIn: string;
    role: string;
    text: string;
    photo: StaticImageData;
  };
};
export const TestimonialCard = ({
  testimonial
}: TestimonialCardProps) => {
  return <Card sx={{
    borderRadius: 0,
    border: 0
  }} data-sentry-element="Card" data-sentry-component="TestimonialCard" data-sentry-source-file="TestimonialCard.tsx">
      <CardActionArea target="_blank" rel="noopener noreferrer nofollow" href={testimonial.linkedIn} sx={{
      py: 3,
      px: 3,
      width: '100%',
      height: 250
    }} data-sentry-element="CardActionArea" data-sentry-source-file="TestimonialCard.tsx">
        <Stack direction="row" spacing={2} mb={2} height={70} data-sentry-element="Stack" data-sentry-source-file="TestimonialCard.tsx">
          <Image src={testimonial.photo} alt={testimonial.name} width={60} height={60} style={{
          borderRadius: '50%',
          objectFit: 'cover'
        }} data-sentry-element="Image" data-sentry-source-file="TestimonialCard.tsx" />
          <Box maxWidth={150} data-sentry-element="Box" data-sentry-source-file="TestimonialCard.tsx">
            <Typography variant="h6" component="h3" sx={{
            display: 'flex',
            alignItems: 'center',
            mb: 1
          }} data-sentry-element="Typography" data-sentry-source-file="TestimonialCard.tsx">
              {testimonial.name}{' '}
              <LinkedInIcon sx={{
              ml: 0.5
            }} htmlColor="#3373C7" data-sentry-element="LinkedInIcon" data-sentry-source-file="TestimonialCard.tsx" />
            </Typography>
            <Typography variant="bodySmall" color="text.primary" data-sentry-element="Typography" data-sentry-source-file="TestimonialCard.tsx">
              {testimonial.role}
            </Typography>
          </Box>
        </Stack>
        <Typography variant="bodySmall" color="text.primary" sx={{
        lineHeight: 1.66,
        fontStyle: 'italic'
      }} data-sentry-element="Typography" data-sentry-source-file="TestimonialCard.tsx">
          {testimonial.text}
        </Typography>
      </CardActionArea>
    </Card>;
};