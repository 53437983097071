import { ColoredDivider } from '@qb/frontend/components/ColoredDivider';
export const ColoredDividerVariant2 = () => {
  return <ColoredDivider.Container height={8} gridTemplateColumns={{
    xs: '40% 40% 20%',
    lg: '30% 28% 28% 14%'
  }} data-sentry-element="unknown" data-sentry-component="ColoredDividerVariant2" data-sentry-source-file="ColoredDividerVariant2.tsx">
      <ColoredDivider.Line bgcolor="common.white" display={{
      xs: 'none',
      lg: 'block'
    }} data-sentry-element="unknown" data-sentry-source-file="ColoredDividerVariant2.tsx" />
      <ColoredDivider.Line bgcolor="primary.main" data-sentry-element="unknown" data-sentry-source-file="ColoredDividerVariant2.tsx" />
      <ColoredDivider.Line bgcolor="secondary.main" data-sentry-element="unknown" data-sentry-source-file="ColoredDividerVariant2.tsx" />
      <ColoredDivider.Line bgcolor="accent.main" data-sentry-element="unknown" data-sentry-source-file="ColoredDividerVariant2.tsx" />
    </ColoredDivider.Container>;
};