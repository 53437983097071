'use client';

import { useEffect, useRef, useState } from 'react';
import dynamic from 'next/dynamic';
import { useRouter } from 'next/router';
import { Box, BoxProps, SxProps } from '@mui/material';
import { useIsUnderBreakpoint } from '@qb/frontend/hooks/mediaQueries';
import { EventEmitter } from '@qb/frontend/utils/EventEmitter';
import { OmniSearchDesktopPopup } from '@/src/components/OmniSearch/components/OmniSearchDesktopPopup/OmniSearchDesktopPopup';
import { useDebouncedQuery } from '@/src/components/OmniSearch/hooks/useDebouncedQuery';
import { useIsUserTyping } from '@/src/components/OmniSearch/hooks/useIsUserTyping';
import { EVENT_HIDE_OMNISEARCH } from '@/src/components/OmniSearch/hooks/useRegisterOmniSearchHandlers';
import { OmniSearchInput, OmniSearchInputG4AEventsProps } from './components/OmniSearchInput';
const OmniSearchMobileDrawer = dynamic(() => import('./components/OmniSearchMobileDrawer/OmniSearchMobileDrawer').then(mod => mod.OmniSearchMobileDrawer), {
  ssr: false
});
type Props = OmniSearchInputG4AEventsProps & {
  inputSx?: SxProps;
  magicIconSx: SxProps;
  width?: BoxProps['width'];
};
export const HeroOmniSearch = ({
  searchInputName,
  inputSx,
  width = {
    xs: 700,
    lg: 800
  },
  magicIconSx
}: Props) => {
  const [isOpen, setIsOpen] = useState(false);
  const {
    query,
    setQuery,
    debouncedQuery
  } = useDebouncedQuery('', 300);
  const isUserTyping = useIsUserTyping(query);
  const ref = useRef<HTMLElement | null>(null);
  const router = useRouter();
  useEffect(() => {
    const handleRouteChange = () => setIsOpen(false);
    router.events.on('routeChangeStart', handleRouteChange);
    return () => router.events.off('routeChangeStart', handleRouteChange);
  }, [router, setIsOpen]);
  const shouldShowMobile = useIsUnderBreakpoint('md');
  if (shouldShowMobile) {
    return <>
        <Box width={['100%', 600]}>
          <OmniSearchInput searchInputName="" value={query} onClick={() => setIsOpen(true)} sx={inputSx} magicIconSx={magicIconSx} />
        </Box>
        <OmniSearchMobileDrawer searchInputName={searchInputName} isVisible={isOpen} setIsVisible={setIsOpen} />
      </>;
  }
  const onFocus = () => {
    setIsOpen(true);
    EventEmitter.publish(EVENT_HIDE_OMNISEARCH);
    const element = ref?.current;
    if (element) {
      const offset = 190;
      const elementPosition = element.getBoundingClientRect().top;
      const offsetPosition = elementPosition + window.scrollY - offset;
      window.scrollTo({
        top: offsetPosition,
        behavior: 'smooth'
      });
    }
  };
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setQuery(e.target.value);
  };
  return <>
      <Box ref={ref} width={width} data-sentry-element="Box" data-sentry-source-file="HeroOmniSearch.tsx">
        <OmniSearchInput searchInputName={searchInputName} value={query} onFocus={onFocus} onChange={onChange} sx={inputSx} magicIconSx={magicIconSx} data-sentry-element="OmniSearchInput" data-sentry-source-file="HeroOmniSearch.tsx" />
      </Box>
      <OmniSearchDesktopPopup query={debouncedQuery} isUserTyping={isUserTyping} ref={ref} isOpen={isOpen} setIsOpen={setIsOpen} marginTop={1} data-sentry-element="OmniSearchDesktopPopup" data-sentry-source-file="HeroOmniSearch.tsx" />
    </>;
};