import { Link as MuiLink } from '@mui/material';
import { LinkThatOpensChat } from './LinkThatOpensChat';

/** In order to generated ld+JSON schemas we also need pure string answers, without JSX.Elements */
const warrantyAnswer = `While our parts may not include the original manufacturer warranty, as Quotebeam is not an authorized surplus dealer or affiliate for the Manufacturer of this product, we stand behind the products we offer on our site. You can return any part within 30 days of delivery (heads up, some re-stocking fees might apply) and if any part is defective, we've got you covered with our own Quotebeam 12-month warranty. Have questions? `;
const paymentTermsAnswer = "Absolutely, we've got you covered. Even though you might only see a credit card option at checkout, we also offer Payment Terms. The approval process? Super easy and lightning-fast. We'll sort it out in less than 15 minutes. Once approved, you'll see the Payment Terms option at checkout.";
export const faqContent = [{
  question: 'Is your in-stock inventory accurate?',
  answer: ['Yes!', "We keep our stock information updated in real-time. When we say a part's in stock, we mean it. No time-wasting, no tricks - just genuine parts ready to be shipped your way."]
}, {
  question: 'How fast can you ship?',
  answer: ["We understand you need your parts ASAP. That's why we rush to get most orders out the door on the same day. Depending on where our warehouses are and what time it is, it might slip to the next day, but hey, we're only human.", "And don't panic if you see multiple shipments - that's just us getting your parts from different spots. No hidden fees here, just the shipping cost you saw at checkout. For our international orders, give us an extra 2-3 days to round up all your parts before we ship.", "Once everything's on the move, we'll ping you with the carrier information and the tracking number."]
}, {
  question: 'Do you provide payment terms?',
  stringAnswer: `${paymentTermsAnswer} If you're interested in Payment Terms, send us an email at customer@quotebeam.com or message us on our online chat`,
  answer: [paymentTermsAnswer, <>
        {`If you're interested in Payment Terms, send us an email at `}
        <MuiLink href="mailto:customer@quotebeam.com">
          customer@quotebeam.com
        </MuiLink>
        {' or message us on our '}
        <LinkThatOpensChat>online chat</LinkThatOpensChat>.
      </>]
}, {
  question: 'Do you ship internationally?',
  answer: ['We deliver worldwide, except for a few countries that the US Government has sanctions on.', "For our international shipments, please allow us an extra 2-3 days before shipping. That's just us figuring out where the parts are and maybe bundling them all into one neat package."]
}, {
  question: 'Where does your inventory come from?',
  answer: ['We help our customers source parts that are difficult to find.', 'We partner with hundreds of smaller regional distributors, and other supply channels to bring all of the inventory (mostly overstock) that is stocked on the shelf at other sources into one place for you to find and purchase.   Quotebeam is not an authorized distributor, authorized reseller, affiliate or representative for the manufacturers listed and products featured on this website.  We are a technology platform that brings the unused surplus inventory into one place for you.']
}, {
  question: 'What is the condition of your parts?',
  answer: ["The parts on our site are unused surplus items sourced through a surplus supplier.  These parts has never been used in a production environment; however, they may have been powered on for testing purposes.  They come in original packaging if applicable, and show no visible signs of use, handling or damage.  The original manufacturer's seal may or may not be present on the the box, and the instructions typically provided with a new product may or may not be included.  The country of origin and language used on the packaging may vary from current offerings, and products may feature older date codes or be from an earlier series than what is currently available from the manufacturer or authorized dealers.  Quotebeam offers 1-year warranty on all products sold through our site.  Note that the original manufacturer warranty may not apply."]
}, {
  question: 'Do you provide warranty?',
  stringAnswer: warrantyAnswer,
  answer: ['Absolutely!', <>
        {warrantyAnswer}
        <LinkThatOpensChat>Chat with our team!</LinkThatOpensChat>
      </>]
}, {
  question: 'What is Quotebeam?',
  answer: ["Great question! Quotebeam simplifies part procurement by providing real-time stock info, and ability to purchase parts sourced from multiple supply channels. Our vast supply chain network provides you access to parts and materials that often may be hard to find, and we bring them to you in one place. We consolidate in-stock inventory from numerous small regional distributors and other supply channels into one convenient place, ensuring you have access and visibility that most users wouldn't otherwise enjoy.  Quotebeam is not an authorized distributor, authorized reseller, affiliate or representative for the manufacturers listed and products featured on this website."]
}, {
  question: 'Can I get discounted pricing?',
  answer: ["We're all about making ordering work for you. Big deals? Let's chat and negotiate – we're here for it. Your first order? Boom, 10% off. We're in for the long haul, caring about what pricing suits you best in our partnership."]
}, {
  question: 'Where are these parts shipping from?',
  answer: ['We have a network of warehouses located across the US where our parts ship from. If you would like more information on a specific part please reach out to us.']
}];