import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { Accordion, AccordionDetails, AccordionSummary, Container, Typography } from '@mui/material';
import { faqContent } from './faqContent';
type Props = {
  isHomePage?: boolean;
};
export const FAQ = ({
  isHomePage = false
}: Props) => {
  const title = isHomePage ? 'FAQ' : 'Frequently asked questions';
  return <>
      <Container sx={{
      py: 6
    }} maxWidth="md" component={isHomePage ? 'div' : 'section'} data-sentry-element="Container" data-sentry-source-file="FAQ.tsx">
        <Typography component="h3" sx={{
        mb: {
          xs: 3,
          md: 5
        },
        typography: {
          xs: 'h4',
          md: 'h3'
        }
      }} align={isHomePage ? 'center' : 'left'} data-sentry-element="Typography" data-sentry-source-file="FAQ.tsx">
          {title}
        </Typography>
        {faqContentEnriched.map(({
        question,
        answer,
        slug
      }, index) => <Accordion key={slug} disableGutters elevation={0} defaultExpanded={index === 0} square sx={{
        maxWidth: 'md',
        my: 1.5,
        borderColor: 'primary.12p',
        '&:before': {
          content: 'none'
        },
        transition: ({
          transitions
        }) => transitions.create(['background-color', 'border-color']),
        '&.Mui-expanded, &:hover': {
          backgroundColor: 'primary.4p',
          borderColor: 'transparent'
        }
      }}>
            <AccordionSummary expandIcon={<ExpandMoreIcon />} aria-controls={`${slug}-content`} id={`${slug}-header`} sx={{
          my: 1,
          px: 4
        }}>
              <Typography component="h4" variant="h5">
                {question}
              </Typography>
            </AccordionSummary>
            <AccordionDetails sx={{
          pt: 0,
          px: 4
        }}>
              {answer.map((answerParagraph, index) => <Typography sx={{
            mb: 1
          }} key={index} variant="bodyLarge">
                  {answerParagraph}
                </Typography>)}
            </AccordionDetails>
          </Accordion>)}
      </Container>
      <script type="application/ld+json" dangerouslySetInnerHTML={{
      __html: structuredData
    }} />
    </>;
};
const slugify = (text: string) => text.toLowerCase().replace(/[^a-z0-9]+/g, '-').replace(/(^-|-$)+/g, '');
const faqContentEnriched = faqContent.map(content => ({
  ...content,
  slug: slugify(content.question)
}));
const structuredData = JSON.stringify({
  '@context': 'https://schema.org',
  '@type': 'FAQPage',
  mainEntity: faqContent.map(faq => ({
    '@type': 'Question',
    name: faq.question,
    acceptedAnswer: {
      '@type': 'Answer',
      text: faq.stringAnswer ? faq.stringAnswer : faq.answer.filter(paragraph => typeof paragraph === 'string').join(' ')
    }
  }))
});