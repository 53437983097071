'use client';

import Image from 'next/image';
import { Box, Container, Typography, useTheme } from '@mui/material';
import { HeroOmniSearch } from '@/src/components/OmniSearch/HeroOmniSearch';
import searchBackground from './assets/searchBackground.png';
export const SearchBannerMobile = () => {
  const theme = useTheme();
  return <Box sx={{
    position: 'relative',
    display: 'flex',
    flexDirection: 'column',
    justifyContent: 'flex-end',
    pt: 4,
    pb: 6
  }} data-sentry-element="Box" data-sentry-component="SearchBannerMobile" data-sentry-source-file="SearchBannerMobile.tsx">
      <Image src={searchBackground} alt="" fill style={{
      objectFit: 'cover'
    }} quality={100} sizes={`(max-width: ${theme.breakpoints.values.sm}px) 90vw, (max-width: ${theme.breakpoints.values.md}px) 40vw, 600px`} data-sentry-element="Image" data-sentry-source-file="SearchBannerMobile.tsx" />
      <Container maxWidth="sm" sx={{
      zIndex: 1
    }} data-sentry-element="Container" data-sentry-source-file="SearchBannerMobile.tsx">
        <Typography component="h2" variant="h4" sx={{
        color: 'primary.contrastText',
        mb: 3
      }} data-sentry-element="Typography" data-sentry-source-file="SearchBannerMobile.tsx">
          Ready to find the parts you needed yesterday?
        </Typography>
        <HeroOmniSearch searchInputName="HomePageSearchBanner" inputSx={{
        height: [42, 80],
        borderRadius: [0, 40],
        fontSize: [16, 18],
        backgroundColor: 'common.white',
        px: [1, 3],
        '& .MuiInputAdornment-root .MuiSvgIcon-root': {
          fontSize: [24, 40],
          color: 'secondary.main'
        }
      }} magicIconSx={{
        width: 60,
        borderRadius: [0, '0 40px 40px 0']
      }} data-sentry-element="HeroOmniSearch" data-sentry-source-file="SearchBannerMobile.tsx" />
      </Container>
    </Box>;
};