import { PropsWithChildren } from 'react';
import { Box, BoxProps } from '@mui/material';
type LineProps = Pick<BoxProps, 'display' | 'bgcolor'>;
const Line = ({
  display,
  bgcolor
}: LineProps) => <Box height="100%" bgcolor={bgcolor} display={display} data-sentry-element="Box" data-sentry-component="Line" data-sentry-source-file="ColoredDivider.tsx" />;
type ColoredDividerContainerProps = {
  gridTemplateColumns: BoxProps['gridTemplateColumns'];
  height?: number;
};
const Container = ({
  children,
  gridTemplateColumns,
  height = 4
}: PropsWithChildren<ColoredDividerContainerProps>) => <Box sx={{
  display: 'grid',
  alignItems: 'center',
  width: '100%',
  gridTemplateColumns,
  height
}} data-sentry-element="Box" data-sentry-component="Container" data-sentry-source-file="ColoredDivider.tsx">
    {children}
  </Box>;
export const ColoredDivider = {
  Line,
  Container
};