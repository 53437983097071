import type { GetServerSideProps, InferGetServerSidePropsType, Metadata } from 'next';
import { NextSeo } from 'next-seo';
import { webQueries } from '@qb/httpRequest/webQueries';
import { PartCategoryTrendingCategoriesResponse } from '@/shared/types/controllers/PartCategoryControllerTypes';
import { PartPopularPartsResponse } from '@/shared/types/controllers/PartControllerTypes';
import { Homepage } from '@/src/components/Homepage/Homepage';
export const metadata: Metadata = {
  description: `Access a vast in-stock inventory for available and hard-to-find parts. Our dedicated team is ready to assist instantly.`
};
type HomeProps = {
  popularParts: PartPopularPartsResponse['parts'];
  trendingCategories: PartCategoryTrendingCategoriesResponse['trendingCategories'];
};
const Home = ({
  trendingCategories,
  popularParts
}: InferGetServerSidePropsType<typeof getServerSideProps>) => {
  return <>
      <NextSeo description={(metadata.description as string)} data-sentry-element="NextSeo" data-sentry-source-file="index.tsx" />
      <Homepage popularParts={popularParts} trendingCategories={trendingCategories} data-sentry-element="Homepage" data-sentry-source-file="index.tsx" />
    </>;
};
export default Home;
export const getServerSideProps = ((async context => {
  const popularParts = await webQueries.part.popularParts.queryFn().catch(err => {
    console.error('Homepage SSR: Failed to fetch popular products');
    console.error(err);
    return [];
  });
  const trendingCategories = await webQueries.partCategory.trendingCategories.queryFn().catch(err => {
    console.error('Homepage SSR: Failed to fetch trending categories');
    console.error(err);
    return [];
  });

  // Cache for 10 minutes
  const maxAge = 60 * 10;
  context.res.setHeader('Cache-Control', `public, maxage=${maxAge}, s-maxage=${maxAge}`);
  return {
    props: {
      popularParts,
      trendingCategories
    }
  };
}) satisfies GetServerSideProps<HomeProps>);