import Image from 'next/image';
import { Box, Container, Grid, Typography } from '@mui/material';
import { HeroOmniSearch } from '@/src/components/OmniSearch/HeroOmniSearch';
import fiveStarsSvg from './assets/five-stars.svg';
import heroImg from './assets/hero.jpg';
export const Hero = () => {
  return <Box component="section" sx={{
    width: '100%',
    height: {
      xs: 550,
      md: 530
    },
    position: 'relative'
  }} data-sentry-element="Box" data-sentry-component="Hero" data-sentry-source-file="Hero.tsx">
      <Image src={heroImg} fill quality={100} priority placeholder="blur" style={{
      objectPosition: 'top center',
      objectFit: 'cover'
    }} alt="Blue-toned image of interlocking metal gears and machinery parts." data-sentry-element="Image" data-sentry-source-file="Hero.tsx" />
      <Container sx={{
      height: '100%'
    }} data-sentry-element="Container" data-sentry-source-file="Hero.tsx">
        <Grid container sx={{
        height: '100%'
      }} data-sentry-element="Grid" data-sentry-source-file="Hero.tsx">
          <Grid item xs={0.5} md={1} lg={2} data-sentry-element="Grid" data-sentry-source-file="Hero.tsx" />
          <Grid item xs={11} md={10} lg={8} sx={{
          height: '100%',
          display: 'flex',
          alignItems: 'center',
          justifyContent: 'center',
          flexDirection: 'column'
        }} data-sentry-element="Grid" data-sentry-source-file="Hero.tsx">
            <Typography textAlign="center" variant="h2" component="h1" color="common.white" mb={4} sx={{
            zIndex: 1
          }} data-sentry-element="Typography" data-sentry-source-file="Hero.tsx">
              The New Way To Search and Buy Automation Parts.
            </Typography>
            <Typography textAlign="center" variant="h4" color="common.white" mb={4} component="p" sx={{
            zIndex: 1
          }} data-sentry-element="Typography" data-sentry-source-file="Hero.tsx">
              Instantly access verified in-stock parts and pricing via
              Quotebeam&apos;s vast supplier network.
            </Typography>
            <HeroOmniSearch searchInputName="HomePageHeroSearch" inputSx={{
            fontSize: 18,
            backgroundColor: 'common.white',
            height: {
              xs: 54,
              md: 80
            },
            pl: {
              xs: 1,
              md: 4
            },
            '& .MuiInputAdornment-root .MuiSvgIcon-root': {
              fontSize: [24, 36],
              color: 'secondary.main'
            }
          }} magicIconSx={{
            width: 65
          }} data-sentry-element="HeroOmniSearch" data-sentry-source-file="Hero.tsx" />
            <Typography textAlign="center" variant="bodyMedium" color="common.white" mt={2} mb={3} sx={{
            zIndex: 1
          }} data-sentry-element="Typography" data-sentry-source-file="Hero.tsx">
              Trusted by thousands of professionals worldwide.
            </Typography>
            <Image src={fiveStarsSvg} alt="Five stars rating" width={102} height={13} style={{
            zIndex: 1
          }} data-sentry-element="Image" data-sentry-source-file="Hero.tsx" />
          </Grid>
        </Grid>
      </Container>
    </Box>;
};