import { ColoredDivider } from '@qb/frontend/components/ColoredDivider';
export const ColoredDividerVariant1 = () => {
  return <ColoredDivider.Container gridTemplateColumns={{
    xs: `40% 40% 20%`,
    lg: `25% 25% 15% 35%`
  }} data-sentry-element="unknown" data-sentry-component="ColoredDividerVariant1" data-sentry-source-file="ColoredDividerVariant1.tsx">
      <ColoredDivider.Line bgcolor="primary.main" data-sentry-element="unknown" data-sentry-source-file="ColoredDividerVariant1.tsx" />
      <ColoredDivider.Line bgcolor="secondary.main" data-sentry-element="unknown" data-sentry-source-file="ColoredDividerVariant1.tsx" />
      <ColoredDivider.Line bgcolor="accent.main" data-sentry-element="unknown" data-sentry-source-file="ColoredDividerVariant1.tsx" />
      <ColoredDivider.Line bgcolor="common.white" display={{
      xs: 'none',
      lg: 'block'
    }} data-sentry-element="unknown" data-sentry-source-file="ColoredDividerVariant1.tsx" />
    </ColoredDivider.Container>;
};