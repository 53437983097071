import { PropsWithChildren } from 'react';
import { Box } from '@mui/material';
import roboticBackground from './assets/roboticBackground.svg';
export const RoboticBackgroundImage = ({
  children
}: PropsWithChildren) => {
  return <Box sx={{
    bgcolor: 'common.white',
    backgroundImage: `url(${roboticBackground})`,
    backgroundSize: 'cover',
    backgroundPosition: 'top'
  }} data-sentry-element="Box" data-sentry-component="RoboticBackgroundImage" data-sentry-source-file="RoboticBackgroundImage.tsx">
      {children}
    </Box>;
};