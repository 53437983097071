'use client';

import Image from 'next/image';
import { Box, Container, Grid, Typography } from '@mui/material';
import { useIsMobile } from '@qb/frontend/hooks/mediaQueries';
import { ColoredDividerVariant1 } from '../ColoredDividers/ColoredDividerVariant1';
import abb from './assets/abb.svg';
import banner from './assets/banner.svg';
import festo from './assets/festo.svg';
import lenze from './assets/lenze.svg';
import nkTechnologies from './assets/nkTechnologies.svg';
import omron from './assets/omron.svg';
import phoenixContact from './assets/phoenixContact.svg';
import schneiderElectric from './assets/schneiderElectric.svg';
import siemens from './assets/siemens.svg';
import smc from './assets/smc.svg';
import turck from './assets/turck.svg';
import wago from './assets/wago.svg';
const brands = (['siemens', 'banner', 'festo', 'smc', 'lenze', 'schneiderElectric', 'turck', 'phoenixContact', 'nkTechnologies', 'omron', 'wago', 'abb'] as const);
const brandToLogoPathMap = {
  siemens,
  banner,
  festo,
  smc,
  lenze,
  schneiderElectric,
  turck,
  phoenixContact,
  nkTechnologies,
  omron,
  wago,
  abb
};
type TrustedBrandsLogoProps = {
  brand: (typeof brands)[number];
};
const TrustedBrandsLogo = ({
  brand
}: TrustedBrandsLogoProps) => {
  const isMobile = useIsMobile();
  const width = isMobile ? 100 : 160;
  const height = isMobile ? 48 : 64;
  const logoPath = brandToLogoPathMap[brand];
  return <Grid item xs={4} md={3} lg={2} sx={{
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center'
  }} data-sentry-element="Grid" data-sentry-component="TrustedBrandsLogo" data-sentry-source-file="TrustedBrands.tsx">
      <Image width={width} height={height} src={logoPath} alt={`${brand} logo`} data-sentry-element="Image" data-sentry-source-file="TrustedBrands.tsx" />
    </Grid>;
};
export const TrustedBrands = () => {
  return <Box component="section" bgcolor="primary.8p" data-sentry-element="Box" data-sentry-component="TrustedBrands" data-sentry-source-file="TrustedBrands.tsx">
      <Container maxWidth="lg" sx={{
      py: {
        xs: 4,
        lg: 6
      }
    }} data-sentry-element="Container" data-sentry-source-file="TrustedBrands.tsx">
        <Typography component="h2" sx={{
        typography: {
          xs: 'h4',
          md: 'h3'
        },
        mb: {
          xs: 3,
          md: 4
        }
      }} textAlign="center" data-sentry-element="Typography" data-sentry-source-file="TrustedBrands.tsx">
          Our trusted brands
        </Typography>
        <Grid container spacing={4} data-sentry-element="Grid" data-sentry-source-file="TrustedBrands.tsx">
          {brands.map(brand => <TrustedBrandsLogo key={brand} brand={brand} />)}
        </Grid>
      </Container>
      <ColoredDividerVariant1 data-sentry-element="ColoredDividerVariant1" data-sentry-source-file="TrustedBrands.tsx" />
    </Box>;
};