import alexMarcyImg from './assets/alexMarcy.jpeg';
import aliGImg from './assets/aliG.jpeg';
import cherylMcCleeryImg from './assets/cherylMcCleery.png';
import jordanWiensImg from './assets/jordanWiens.jpeg';
import michaelHodorImg from './assets/michaelHodor.jpeg';

export const testimonialsList = [
  {
    name: 'Jordan Wiens, P.E.',
    linkedIn: 'https://www.linkedin.com/in/jordanwiens',
    role: 'Managing Partner, Xenon Inc.',
    text: 'Excellent to see innovation in the Control Systems Integrator space like what they are doing at Quotebeam. Simplify the procurement process. Make the job of engineers and designers better.',
    photo: jordanWiensImg,
  },
  {
    name: 'Cheryl McCleery',
    linkedIn: 'https://www.linkedin.com/in/cheryl-mccleery-19277245',
    role: 'Buyer, Max Process Equipment LLC',
    text: "[Quotebeam] offers competitive pricing and lead times. The website is easy to use and customer service reps are responsive, knowledgeable and friendly. I'm thankful a colleague introduced me to Quotebeam.",
    photo: cherylMcCleeryImg,
  },
  {
    name: 'Ali G',
    linkedIn: 'https://www.linkedin.com/in/alicia-gilpin-ali-g-8675b322',
    role: 'Director of Engineering, Process and Controls Engineering LLC',
    text: "Quotebeam is awesome sauce. Normal distributors just can't pull off what Quotebeam has built infrastructure for. Make an account today and see what your options really are when you're in a bind.",
    photo: aliGImg,
  },
  {
    name: 'Michael Hodor',
    linkedIn: 'https://www.linkedin.com/in/michael-hodor',
    role: 'Head of Tech, Roasting Plant Coffee',
    text: 'At a time when supply chain issues made finding essential automation parts an uphill battle, Quotebeam stepped in, navigated the complexities, and delivered exactly what I needed.',
    photo: michaelHodorImg,
  },
  {
    name: 'Alex Marcy',
    linkedIn: 'https://www.linkedin.com/in/alex-marcy-599378251',
    role: 'CEO, Corso Systems',
    text: "I don't always order panel hardware but when I do it's cool to be able to use Quotebeam and do it without having to call a distributor deal with 15 emails & calls back forth just to get a price.",
    photo: alexMarcyImg,
  },
];
