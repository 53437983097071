import Image from 'next/image';
import { Box } from '@mui/material';
import heroImg from './assets/hero.jpg';
export const HeroImageDivider = () => <Box sx={{
  width: '100%',
  height: 42,
  position: 'relative'
}} data-sentry-element="Box" data-sentry-component="HeroImageDivider" data-sentry-source-file="HeroImageDivider.tsx">
    <Image fill src={heroImg} alt="Blue-toned image of interlocking metal gears and machinery parts." style={{
    objectPosition: 'center',
    objectFit: 'cover'
  }} data-sentry-element="Image" data-sentry-source-file="HeroImageDivider.tsx" />
  </Box>;