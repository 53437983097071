import React from 'react';
import CheckCircleIcon from '@mui/icons-material/CheckCircle';
import { Box, Chip, Stack, Typography } from '@mui/material';
export const QuotebeamFeatures = () => {
  return <Stack data-sentry-element="Stack" data-sentry-component="QuotebeamFeatures" data-sentry-source-file="QuotebeamFeatures.tsx">
      <Typography variant="h3" fontSize={24} mb={3} sx={{
      display: {
        xs: 'block',
        md: 'none'
      },
      typography: 'h4'
    }} align="center" data-sentry-element="Typography" data-sentry-source-file="QuotebeamFeatures.tsx">
        Why Quotebeam? 💙
      </Typography>
      <QuotebeamFeature icon="🌐" title="Extensive Network" description="Find the component you're looking for from our network of distributors, offering over 1M in-stock parts." data-sentry-element="QuotebeamFeature" data-sentry-source-file="QuotebeamFeatures.tsx" />
      <QuotebeamFeature icon={<Chip icon={<CheckCircleIcon />} color="success" label={<>
                Verified stock: <strong>23</strong>
              </>} sx={{
      width: 'fit-content',
      mt: 0.5
    }} size="small" />} title="Always Available" description="We are streamlining your sourcing, quickly providing quotes and finding the exact part you need." data-sentry-element="QuotebeamFeature" data-sentry-source-file="QuotebeamFeatures.tsx" />
      <QuotebeamFeature icon="🚚" title="Fast Shipping and Delivery" description="When you place an order, we process it quickly, ensuring fast delivery with full transparency." data-sentry-element="QuotebeamFeature" data-sentry-source-file="QuotebeamFeatures.tsx" />
      <QuotebeamFeature icon="🤝" title="Trusted Partner" description="We're all in for your success! Always delivering on our promises and cheering you on every step of the way." data-sentry-element="QuotebeamFeature" data-sentry-source-file="QuotebeamFeatures.tsx" />
    </Stack>;
};
type QuotebeamFeatureProps = {
  icon: React.ReactNode | string;
  title: string;
  description: string;
};
const QuotebeamFeature = ({
  icon,
  title,
  description
}: QuotebeamFeatureProps) => <Box mb={3} data-sentry-element="Box" data-sentry-component="QuotebeamFeature" data-sentry-source-file="QuotebeamFeatures.tsx">
    <Stack direction="row" alignItems="center" mb={1} data-sentry-element="Stack" data-sentry-source-file="QuotebeamFeatures.tsx">
      <Typography variant="h5" component="h3" mr={1.5} data-sentry-element="Typography" data-sentry-source-file="QuotebeamFeatures.tsx">
        {title}
      </Typography>
      {icon}
    </Stack>
    <Typography color="text.primary" variant="bodyLarge" data-sentry-element="Typography" data-sentry-source-file="QuotebeamFeatures.tsx">
      {description}
    </Typography>
  </Box>;